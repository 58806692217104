import React, { useState, useEffect } from "react";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import "./Admin.css";
import RunningCampaign from "./RunningCampaign";
import UpdateInfluxPoint from "./UpdateInfluxPoint";
import CreatorList from "./CreatorList";
import UpdateCreator from "./updateCreator";
//import DemoComp from "./DemoComp";

const Admin = ({ creators }) => {
  const [searchTerm, setSearchTerm] = useState("");
  //  console.log("creators",creators);
  // creators.forEach((creator) => {
  //   console.log(creator.displayname);
  // });
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCreator, setSelectedCreator] = useState("");
  const [creatorData, setCreatorData] = useState(null);
  const [accessPopup, setAccessPopup] = useState(true);

  useEffect(() => {
    console.log("creator", creatorData);
  }, [creatorData]);

  const [formData, setFormData] = useState({
    campaignId: "",
    creatorId: "",
    creatorName: "",
    views: "",
    likes: "",
    comments: "",
    shares: "",
  });

  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const filteredCreators = creators.filter(
  //   (creator) =>
  //     creator.displayname && // Check if displayname exists
  //     creator.displayname.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  // Fetch campaigns on component load
  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await axios.get(`${baseUrl}/campaign/fetchCampaign`);
        if (response.status === 200) {
          setCampaigns(response.data);

          // Log campaign names and creator display names
          response.data.forEach((campaign) => {
            // console.log("Campaign Name:", campaign.campaignName);
            campaign.creators.forEach((creator) => {
              // console.log("Creator Display Name:", creator.displayname);
            });
          });
        }
      } catch (error) {
        console.error("Error fetching campaigns:", error);
      }
    };

    fetchCampaigns();
  }, []);

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle creator selection
  const handleCreatorChange = (e) => {
    const creatorId = e.target.value;
    setSelectedCreator(creatorId);

    const selectedCampaignData = campaigns.find(
      (campaign) => campaign._id === selectedCampaign
    );

    const creator = selectedCampaignData.creators.find(
      (c) => c._id === creatorId
    );

    setFormData({
      ...formData,
      creatorId: creator._id,
      creatorName: creator.displayname,
    });
  };

  // Handle form submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    //  console.log("Form data:", formData);
    try {
      const response = await axios.post(
        `${baseUrl}/campaign/updateCampaign`,
        JSON.stringify(formData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        alert("Data updated successfully!");
      } else {
        alert("Failed to update data.");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("An error occurred while updating data.");
    }
  };

  // Handle campaign selection
  const handleCampaignChange = (e) => {
    const campaignId = e.target.value;
    setSelectedCampaign(campaignId);
    console.log("Selected campaign:", campaignId);
    const campaign = campaigns.find((c) => c._id === campaignId);
    setFormData({ ...formData, campaignId: campaignId });
  };



  const [accessCode, setAccessCode] = useState("");
  const predefinedCode = "2123";

  useEffect(() => {
    const savedAccessCode = localStorage.getItem("accessCode");
    if (savedAccessCode === predefinedCode) {
      setAccessPopup(false);
    }
  }, []);

  const handleAccessCodeChange = (e) => {
    setAccessCode(e.target.value);
  };

  const handleAccessCodeSubmit = () => {
    if (accessCode === predefinedCode) {
      localStorage.setItem("accessCode", accessCode);
      setAccessPopup(false);
    } else {
      alert("Incorrect code. Please try again.");
    }
  };

  return (
    <>
      {accessPopup && (
        <>
         
          <div
            className="modal-backdrop"
            style={{ backgroundColor: "black" }}
          ></div>

          <div className="modal show d-block">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Enter Access Code</h5>
                </div>
                <div className="modal-body">
                  <p>Please enter the 4-digit access code to proceed.</p>
                  <input
                    type="text"
                    className="form-control"
                    value={accessCode}
                    onChange={handleAccessCodeChange}
                    maxLength="4"
                  />
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-primary"
                    onClick={handleAccessCodeSubmit}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )} 


      <div className="admin-container d-flex">
        <div className="admin-right">
          <form
            onSubmit={handleSubmit}
            className="p-4 border rounded shadow-sm d-flex flex-column flex-md-row"
          >
            <div className="mb-3 me-md-3">
              <label className="form-label">Select Campaign</label>
              <select
                className="form-select"
                value={selectedCampaign}
                onChange={handleCampaignChange}
                required
              >
                <option value="">Choose Campaign</option>
                {Array.isArray(campaigns) &&
                  campaigns.map((campaign) => (
                    <option key={campaign._id} value={campaign._id}>
                      {campaign.campaignName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Select Creator</label>
              <select
                className="form-select"
                value={selectedCreator}
                onChange={handleCreatorChange}
                required
              >
                <option value="">Choose Creator</option>
                {selectedCampaign &&
                  campaigns
                    .find((campaign) => campaign._id === selectedCampaign)
                    ?.creators.map((creator) => (
                      <option key={creator._id} value={creator._id}>
                        {creator.displayname}
                      </option>
                    ))}
              </select>
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Views</label>
              <input
                type="number"
                name="views"
                className="form-control"
                value={formData.views}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Likes</label>
              <input
                type="number"
                name="likes"
                className="form-control"
                value={formData.likes}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Comments</label>
              <input
                type="number"
                name="comments"
                className="form-control"
                value={formData.comments}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="mb-3 me-md-3">
              <label className="form-label">Shares</label>
              <input
                type="number"
                name="shares"
                className="form-control"
                value={formData.shares}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="container" style={{ height: "auto" }}>
        <RunningCampaign creators={creators}/>
      </div>

      <div className="container" style={{height:"auto"}}> 
        <CreatorList creators={creators}/>
      </div>
      <div className="container" style={{height:"auto"}}> 
        <UpdateCreator creators={creators}/>
      </div>
    </>
  );
};

export default Admin;
