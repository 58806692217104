// Chating Component
import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import axios from "axios";
import baseUrl from "../../api/universalApi";
import { IoSend } from "react-icons/io5";
import useSendMessage from "../../context/useSendMessage.js";
import useGetMessage from "../../context/useGetMessage.js";


const Chating = () => {
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [activeUser, setActiveUser] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [chats, setChats] = useState({});
  const [brands, setBrands] = useState([]);



  
    const user = useSelector((state) => state.auth.authData?.user);
  const campaigns = useSelector((state) => state.campaigns.campaigns?.data || []);



  
   

  useEffect(() => {
    const fetchBrandData = async (brandId) => {
      try {
        const { data } = await axios.get(`${baseUrl}/auth2/campaignBrand/${brandId}`);
        return data.data;
      } catch (error) {
        console.error("Error fetching brand data:", error);
        return null;
      }
    };

    const initializeUsers = async () => {
      if (user?.userType === "Brand") {
        const brandCampaigns = campaigns.filter(
          (campaign) => campaign.brandID === user._id
        );

        if (brandCampaigns.length > 0) {
          const creators = brandCampaigns.flatMap((campaign) => campaign.creators);
          setOnlineUsers(creators);
        }
      } else if (user?.userType === "Influencer") {
        const influencerCampaigns = campaigns.filter((campaign) =>
          campaign.creators.some((creator) => creator._id === user._id)
        );

        if (influencerCampaigns.length > 0) {
          const brandIds = [...new Set(influencerCampaigns.map((campaign) => campaign.brandID))];
          const fetchedBrands = await Promise.all(
            brandIds.map((brandId) => fetchBrandData(brandId))
          );
          setBrands(fetchedBrands.filter((brand) => brand));
          setOnlineUsers(fetchedBrands.filter((brand) => brand));
        }
      }
    };

    initializeUsers();

    // socket.emit("join_chat", user);

    // socket.on("update_online_users", (users) => {
    //   console.log("Online users updated:", users);
    //   setOnlineUsers(users); // Update the list of online users
    // });

    // socket.on("receive_private_message", ({ sender, message }) => {
    //   setChats((prevChats) => ({
    //     ...prevChats,
    //     [sender]: [...(prevChats[sender] || []), { sender, message }],
    //   }));
    // });

    // return () => {
    //   socket.disconnect();
    // };
  }, [user, campaigns]);

  const handleSendMessage = async () => {
    if (newMessage.trim() && activeUser) {
      const message = newMessage.trim();

    

      // Update chat UI immediately
      setChats((prevChats) => ({
        ...prevChats,
        [activeUser._id]: [
          ...(prevChats[activeUser._id] || []),
          { sender: "You", message },
        ],
      }));

      setNewMessage("");
    }

    if (user && user?.userType === "Brand") {
      await sendMessages(
        message,
        activeUser._id, //influencer Id 
        user._id, //BrandId
        user.userType,
      );
      setMessage("");
    } else {
      await sendMessages(
        message,

        user._id, //BrandId
        activeUser._id, //influencer Id 
        user.userType,
      );
      setMessage("");
    }
  };

  const [message, setMessage] = useState("");
  const { loading, sendMessages } = useSendMessage();

  const handleSubmit = async (e) => {
    //e.preventDefault()
    console.log(e);
    e.preventDefault();
    if (user && user?.userType === "Brand") {
      await sendMessages(
        message,
        activeUser._id, //influencer Id 
        user._id, //BrandId
        user.userType,
      );
      setMessage("");
    } else {
      await sendMessages(
        message,

        user._id, //BrandId
        activeUser._id, //influencer Id 
        user.userType,
      );
      setMessage("");
    }

  };


  

  

   


  /////////////////////////

  const { messages } = useGetMessage(activeUser);
 // console.log(messages)

  const lastMsgRef = useRef();
  useEffect(() => {
    setTimeout(() => {
      if (lastMsgRef.current) {
        lastMsgRef.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 100);
  }, [messages]);

  useEffect(() => {
   // console.log(messages)
    setChats(messages)
  }, [messages])



  return (
    <div className="flex h-screen">
      <div className="w-1/4 max-w-sm bg-white border-r border-gray-200">
        <div className="p-4 border-b">
          <h1 className="text-xl font-bold text-gray-700">
            {user?.userType === "Brand" ? "Creators" : "Brands"}
          </h1>
        </div>

        <ul className="px-2">
          {onlineUsers.length > 0 ? (
            onlineUsers.map((userData, index) => (
              <li
                key={index}
                onClick={() => setActiveUser(userData)}
                className={`flex items-center p-2 rounded cursor-pointer hover:bg-gray-100 ${activeUser?._id === userData._id ? "bg-gray-200" : ""
                  }`}
              >
                <img
                  src={userData.profile_image || "/default-profile.png"}
                  alt="profile"
                  className="w-8 h-8 rounded-full"
                />
                <div className="ml-3">
                  <h2 className="text-sm font-semibold text-gray-700">
                    {userData.displayname}
                  </h2>
                </div>
              </li>
            ))
          ) : (
            <p className="text-gray-500 text-sm px-4">No users online</p>
          )}
        </ul>
      </div>

      <div className="flex-grow flex flex-col">
        {activeUser ? (
          <>
            <div className="flex items-center justify-between bg-white p-4 border-b">
              <div className="flex items-center">
                <img
                  src={activeUser.profile_image || "/default-profile.png"}
                  alt="profile"
                  className="w-8 h-8 rounded-full"
                />
                <div className="ml-3">
                  <h2 className="text-lg font-bold">{activeUser.displayname}</h2>
                </div>
              </div>
            </div>

            <div className="flex-grow overflow-y-auto p-4 bg-gray-50">
              {chats.length > 0 ? (
                chats.map((chat, index) => (
                  <div
                    key={index}
                    className={`flex ${chat.senderId === activeUser._id ? "chat chat-start " : "chat chat-end justify-end"} mb-0`}
                  >
                    <div
                      className={`max-w-xs rounded-full ${chat.senderId === activeUser._id
                        ? "chat-bubble mb-0 bg-gray-400 font-bold "
                        : "chat-bubble mb-0"
                        }`}
                    >
                      <p className="mb-0 text-white">{chat.message}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center">No messages yet.</p>
              )}
            </div>

            <div className="flex items-center  p-2 pb-0 transparent">
              {/* <form onSubmit={handleSubmit} className="flex w-full items-center justify-center flex-row "> */}
                <div className="w-full">
                  <input
                    placeholder="Type a message"
                    className="flex-grow px-4 py-2 bg-white w-full border-2 rounded-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="">
                  <button onClick={handleSendMessage}
                    className="ml-3 bg-blue-500 text-white px-3 py-2 border-1 rounded-full hover:bg-blue-600"
                  >
                  <IoSend />
                  </button>

                </div>
              {/* </form> */}
            </div>
          </>
        ) : (
          <div className="flex-grow flex items-center justify-center">
            <p className="text-gray-500 text-sm">Select a user to start chatting.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chating;
